<template>


  <div class="container">


    <div class="department-head-vacancy-form mt-4">
      <h4 style="text-align: center">Список дисциплин</h4>


      <!-- table start -->
      <div class="table-responsive-md mt-4">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">Код дисциплины</th>
            <th scope="col">Название дисциплины</th>
            <th scope="col">Кафедра</th>
            <th scope="col">Язык обучения</th>
            <th scope="col">Уровень обучения</th>
            <th scope="col">Отправить</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item,index) in vacancy_form.educationDisciplineVacancyInfos"
              :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ item.discipline.code }}</td>
            <td>{{ item.discipline.name }}</td>
            <td>{{ item.discipline.department.name_ru }}</td>
            <td>{{ item.discipline.language.native_name }}</td>
            <td>{{ item.discipline.studyLevel.name }}</td>
            <td>
              <button class="btn btn-success" type="button"
                      @click="confirmDisciplineVacancy(item.discipline.id)"
                      :disabled="false"
                      :class="{ disabled: false }">
                Отправить на вакансию
              </button>
            </td>

          </tr>
          </tbody>
        </table>
      </div>
      <!-- table end -->

      <nav v-if="pageCount" class="d-flex justify-content-center" aria-label="Page navigation example">
        <Paginate
            v-model="page"
            :page-count="pageCount"
            :click-handler="paginateHandler"
            :prev-text="'Предыдущий'"
            :next-text="'Следующий'"
            :container-class="'pagination'"
            :page-class="'page-item'">
        </Paginate>
      </nav>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import Paginate from '../common/Paginate.vue'
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: 'DepartmentHeadVacancy',
  components: {
    // Paginate
  },

  data() {
    return {
      page: +this.$route.query.page || 1,
      discipline_id: null,
      discipline_vacancy_id: null
    }
  },
  computed: {
    ...mapState('vacancy', ['vacancy_form']),
    getUserDepartmentId(){
      console.log(getCookie('DEPARTMENT_ID'), 'DEPARTMENT_ID')
      return getCookie('DEPARTMENT_ID');
    }
  },

  methods: {
    ...mapActions('vacancy', ['GET_EDUCATION_DISCIPLINE_VACANCY', 'POST_DISCIPLINE_VACANCY_DEPARTMENT_STATUS']),

    async paginateHandler(page) {
      this.page = page
      await this.changePage()
    },
    async changePage() {
      this.$router.push(`${this.$route.path}?page=${this.page}`)
      let department_id = this.getUserDepartmentId;
      await this.GET_EDUCATION_DISCIPLINE_VACANCY({page: this.page, department_id: department_id})
    },
    confirmDisciplineVacancy(id) {
      this.POST_DISCIPLINE_VACANCY_DEPARTMENT_STATUS(id)
    },

  },
  async mounted() {
    let department_id = this.getUserDepartmentId;
    await this.GET_EDUCATION_DISCIPLINE_VACANCY({page: this.page, department_id: department_id});
  }
}
</script>
